<template>
  <module-template :name="name" v-model="value">
    <v-row class="module-form-row">
      <v-col cols="12">
        <d-select
          label="Berechtigung"
          hide-details
          :disabled="!canEditPermissions"
          v-model="value.role"
          :items="permissionItems"
        ></d-select>
      </v-col>
    </v-row>
    <v-row class="module-form-row">
      <v-col cols="12">
        <d-select
          label="Ticketübersicht"
          hide-details
          :disabled="!canEditPermissions"
          v-model="value.options.dldbworkflow"
          :items="ticketItems"
        ></d-select>
      </v-col>
    </v-row>
  </module-template>
</template>

<script>
import { mapState } from 'vuex'
import moduleTemplate from './module-template'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  components: {
    moduleTemplate
  },
  data() {
    return {
      permissionItems: [
        {
          value: 99,
          text: 'Angebote Lesen ohne Export',
          requiresDDM: false,
        },
        {
          value: 6,
          text: 'Engagementangebote einstellen und Angebote lesen',
          requiresDDM: false,
        },
        {
          value: 5,
          text: 'Nur Engagementangebote einstellen',
          requiresDDM: false,
        },
        {
          value: 4,
          text: 'KoopMA',
          requiresDDM: false,
        },
        {
          value: 3,
          text: 'Änderung Gliederungsdaten und Rechte',
          requiresDDM: false,
        },
        {
          value: 2,
          text: 'Änderung Gliederungsdaten ohne Rechte',
          requiresDDM: false,
        },
        {
          value: 1,
          text: 'DDL Administrator',
          requiresDDM: true,
        }
      ],
      ticketItems: [
        {
          value: 0,
          text: 'keine'
        },
        {
          value: 1,
          text: 'Leserechte'
        },
        {
          value: 2,
          text: 'Weiterleiten/Statusbearbeitung'
        }
      ]
    }
  },
  methods: {
    filterRoles() {
      this.permissionItems.forEach(item => {
        // disable selectable roles, based on currently logged in users permissions
        item.disabled = item.requiresDDM && this.currentMandant.role_id !== 1
      })
    },
  },
  computed: {
    ...mapState({
      acl: state => state.account.acl,
      mandants: state => state.account.mandants,
    }),
    currentMandant() {
      return this.mandants.find(mandant => mandant.selected)
    },
    editingDisabled() {
      // disable editing, iff dldb role is ddm, but currently logged in users isn't
      return this.value.role === 4 && this.currentMandant.role_id !== 1
    },
    canEditPermissions() {
      if (!this.acl) {
        return false;
      }
      return !!this.acl.module_authorization_edit && !this.editingDisabled;
    }
  },
  watch: {
    mandants: {
      deep: true,
      immediate: true,
      handler () {
        this.filterRoles()
      }
    }
  }
}
</script>
